import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';
import {BaseObject} from '../base-object';

export class DownloadsPageComponentItem extends BaseObject {
  public title: string;
  public file: ApogeeFile;

  constructor(el: any = {}) {
    super(el);

    this.title = el.title || '';
    this.file = new ApogeeFile(el.file);
  }

  public applyStringTransformer(
    transformer: StringTransformer,
  ): DownloadsPageComponentItem {
    const res = new DownloadsPageComponentItem(this.toJson());

    res.title = transformer.transform(this.title);

    return res;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      file: this.file,
    };
  }
}

export class DownloadsPageComponent extends SectionPageComponent<
  DownloadsPageComponent,
  'downloads'
> {
  public downloads: DownloadsPageComponentItem[];

  constructor(el: any = {}) {
    super(
      el,
      'downloads',
      {hasTitle: true, required: true},
      {hasText: true, required: true},
    );

    this.downloads = (el.downloads || []).map(
      (download: any) => new DownloadsPageComponentItem(download),
    );
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      downloads: this.downloads.map(download => download.toJson()),
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle', 'downloads.title'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): DownloadsPageComponent {
    const res = new DownloadsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};
    res.downloads = this.downloads.map(download =>
      download.applyStringTransformer(transformer),
    );

    return res;
  }
}
