import {PageComponent} from './page-component';
import {PageComponentType} from './page-component-type';
import {EMPTY_RICH_TEXT, RichText} from '../rich-text';
import {AbstractPageType} from '../abstract-page';

export abstract class SectionPageComponent<
  Self extends SectionPageComponent<Self, T>,
  T extends PageComponentType,
> extends PageComponent<Self, T> {
  public hasSection = true;
  public readonly hasTitle: boolean;
  public readonly titleRequired: boolean;
  public readonly hasText: boolean;
  public readonly textRequired: boolean;

  public sectionStyle: 'normal' | 'alternate';
  public sectionTitle: string;
  public sectionText: RichText;

  protected constructor(
    el: any = {},
    type: T,
    title:
      | {
          hasTitle: false;
          required: false;
        }
      | {hasTitle: true; required: boolean} = {
      hasTitle: true,
      required: true,
    },
    text:
      | {
          hasText: false;
          required: false;
        }
      | {hasText: true; required: boolean} = {
      hasText: true,
      required: false,
    },
    needPageComponentTypes: AbstractPageType[] = [],
  ) {
    super(el, type, needPageComponentTypes);

    if (title.required && !title.hasTitle) {
      throw new Error('Title cannot be required if it is not present');
    }

    if (text.required && !text.hasText) {
      throw new Error('Text cannot be required if it is not present');
    }

    this.sectionStyle = el.sectionStyle || 'normal';
    this.sectionTitle = el.sectionTitle || '';
    this.sectionText = el.sectionText ?? EMPTY_RICH_TEXT;
    this.hasTitle = title.hasTitle;
    this.titleRequired = title.required;
    this.textRequired = text.required;
    this.hasText = text.hasText;
  }

  override toJson(): any {
    const parent: any = super.toJson();
    let title = this.hasTitle
      ? {
          sectionTitle: this.sectionTitle,
        }
      : {};

    let text = this.hasText
      ? {
          sectionText: this.sectionText,
        }
      : {};
    return {
      ...parent,
      sectionStyle: this.sectionStyle,
      ...title,
      ...text,
    };
  }
}
