import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class ReviewsMarqueePageComponent extends SectionPageComponent<
  ReviewsMarqueePageComponent,
  'reviewsMarquee'
> {
  constructor(el: any = {}) {
    super(
      el,
      'reviewsMarquee',
      {
        hasTitle: true,
        required: false,
      },
      {
        hasText: false,
        required: false,
      },
    );
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new ReviewsMarqueePageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
    };
  }
}
