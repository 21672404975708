import {BaseWebsiteResource} from "./base-website-resource";
import {PageComponent} from "./page-components/page-component";
import {PageComponentFactory} from "./page-components/page-component-factory";
import {Menu, MenuIcon} from "./menu";
import {PageContext} from "./page-context";
import {ApogeeFile} from "./apogee-file";

export class Page extends BaseWebsiteResource {
  public title: string;
  public description: string;
  public displayTitle: string;
  public uriSegment: string;
  public readonly menuId: string;
  public parentMenu: Menu | null;
  public sortOrder: number;
  public menuIcon: MenuIcon | undefined;
  public primaryImage: ApogeeFile;
  public components: PageComponent<any, any>[];
  public pageContexts: PageContext[];
  public showConfiguratorBannerInMegaMenu: boolean;
  public hideInMainMenu: boolean;
  public condensedChildrenStyle: boolean;
  public indexPage: boolean;


  constructor(el: any = {}) {
    super(el);
    this.menuId = el.menuId;
    this.title = el.title ?? "";
    this.description = el.description ?? "";
    this.displayTitle = el.displayTitle ?? "";
    this.uriSegment = el.uriSegment ?? "";
    this.sortOrder = el.sortOrder || 0;
    this.menuIcon = el.menuIcon;
    this.primaryImage = new ApogeeFile(el.primaryImage);
    this.parentMenu = el.parentMenu ? new Menu(el.parentMenu) : null;
    this.pageContexts = (el.pageContexts || []).map((p: any) => new PageContext(p));

    this.showConfiguratorBannerInMegaMenu = el.showConfiguratorBannerInMegaMenu || false;
    this.hideInMainMenu = el.hideInMainMenu || false;
    this.condensedChildrenStyle = el.condensedChildrenStyle || false;
    this.indexPage = el.indexPage ?? true;

    const isPageComponent = (component: PageComponent<any, any> | undefined): component is PageComponent<any, any> => !!component;
    this.components = (el.components || [])
      .map((c: any) => new PageComponentFactory().create({...c, global: this.global}))
      .filter(isPageComponent);
  }


  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      description: this.description,
      displayTitle: this.displayTitle,
      uriSegment: this.uriSegment,
      sortOrder: this.sortOrder,
      menuIcon: this.menuIcon,
      primaryImage: this.primaryImage,
      parentMenu: this.parentMenu ? {id: this.parentMenu.id} : null,
      components: this.components.map(component => component.toJson()),
      showConfiguratorBannerInMegaMenu: this.showConfiguratorBannerInMegaMenu,
      hideInMainMenu: this.hideInMainMenu,
      indexPage: this.indexPage,
      condensedChildrenStyle: this.condensedChildrenStyle,
      pageContexts: this.pageContexts,
    };
  }

  public get pageUrl(): string {
    const segments = [this.uriSegment];

    let menu = this.parentMenu;
    while (menu) {
      segments.push(menu.uriSegment);
      menu = menu.parentMenu;
    }

    return ('/' + segments.reverse().join('/')).replace(/\/+/g, '/');
  }

  public override clone(): Page {
    return new Page({
      title: this.title,
      description: this.description,
      displayTitle: this.displayTitle,
      uriSegment: this.uriSegment,
      sortOrder: this.sortOrder,
      menuIcon: this.menuIcon,
      primaryImage: this.primaryImage,
      parentMenu: this.parentMenu?.toJson() || null,
      components: this.components.map(component => component.clone().toJson()),
      showConfiguratorBannerInMegaMenu: this.showConfiguratorBannerInMegaMenu,
      hideInMainMenu: this.hideInMainMenu,
      indexPage: this.indexPage,
      condensedChildrenStyle: this.condensedChildrenStyle,
      pageContexts: this.pageContexts,
    });
  }
}
