import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {LicenseClass} from '../license-class';

export class LicenseClassInfoPageComponent extends SectionPageComponent<
  LicenseClassInfoPageComponent,
  'licenseClassInfo'
> {
  public licenseClasses: LicenseClass[];
  public allowOverrideThroughPageContext: boolean;

  constructor(el: any = {}) {
    super(
      el,
      'licenseClassInfo',
      {hasTitle: true, required: true},
      {hasText: false, required: false},
    );

    this.licenseClasses = (el.licenseClasses || []).map(
      (r: any) => new LicenseClass(r),
    );
    this.allowOverrideThroughPageContext = el.allowOverrideThroughPageContext || true;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      licenseClasses: this.licenseClasses.map((r: LicenseClass) => r.toJson()),
      allowOverrideThroughPageContext: this.allowOverrideThroughPageContext,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): LicenseClassInfoPageComponent {
    const res = new LicenseClassInfoPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
