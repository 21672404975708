import {VideoModel} from '../video-model';
import {StringTransformer} from './string-transformer';
import {SectionPageComponent} from './section-page-component';

export class TestimonialPageComponent extends SectionPageComponent<
  TestimonialPageComponent,
  'testimonial'
> {
  // Todo: properties should not come out of the model
  public name: string;
  public subtitle: string;
  public text: string;
  public rating: number;
  public video: VideoModel;

  constructor(el: any = {}) {
    super(el, 'testimonial', {hasTitle: false, required: false});

    this.name = el.name || '';
    this.text = el.text || '';
    this.subtitle = el.subtitle || '';
    this.rating = el.rating || 5;
    this.video = new VideoModel(el.video || {});
  }
  public override getSearchKeys(): string[] {
    return ['name', 'text', 'subtitle'];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new TestimonialPageComponent(this.toJson());

    res.name = transformer.transform(this.name);
    res.text = transformer.transform(this.text);
    res.subtitle = transformer.transform(this.subtitle);

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      rating: this.rating,
      name: this.name,
      subtitle: this.subtitle,
      text: this.text,
      // video: this.video,
    };
  }
}
