import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {OpeningTime} from '../opening-time';

export class TimetablePageComponent extends SectionPageComponent<
  TimetablePageComponent,
  'timetable'
> {
  public times: OpeningTime[];

  constructor(el: any = {}) {
    super(
      el,
      'timetable',
      {hasTitle: true, required: true},
      {hasText: true, required: false},
    );

    this.times = el.times || [];
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): TimetablePageComponent {
    const res = new TimetablePageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      times: this.times,
    };
  }
}
