import {BaseWebsiteResource} from '../base-website-resource';
import {PageComponentType} from './page-component-type';
import {StringTransformer} from './string-transformer';
import {PageComponentFactory} from './page-component-factory';
import {AbstractPageType} from '../abstract-page';

export abstract class PageComponent<
  Self extends PageComponent<Self, T>,
  T extends PageComponentType,
> extends BaseWebsiteResource {
  public readonly type: T;
  public targetIndex: number;

  public needsPageContexts: AbstractPageType[] = [];

  // Transient field needed only in frontend
  public hiddenInSlot = false;

  protected constructor(
    el: any = {},
    type: T,
    needsPageContexts: AbstractPageType[] = [],
  ) {
    super(el);
    this.type = type;
    this.targetIndex = el.targetIndex ?? 0;
    this.needsPageContexts = needsPageContexts;
  }

  // List of strings of a PageComponent that should be indexed in the search (in dot notation).
  abstract getSearchKeys(): string[];

  abstract applyStringTransformer(transformer: StringTransformer): Self;

  override toJson(): any {
    return {
      ...super.toJson(),
      type: this.type,
      targetIndex: this.targetIndex,
    };
  }

  override clone(): PageComponent<any, any> {
    return new PageComponentFactory().create({
      ...this.toJson(),
      id: undefined,
    }) as PageComponent<any, any>;
  }
}
