import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';

export class ImageGalleryPageComponent extends SectionPageComponent<
  ImageGalleryPageComponent,
  'imageGallery'
> {
  public images: ApogeeFile[];
  public allowOverrideThroughPageContext: boolean;

  constructor(el: any = {}) {
    super(
      el,
      'imageGallery',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );
    this.images = (el.images || []).map((image: any) => new ApogeeFile(image));
    this.allowOverrideThroughPageContext = el.allowOverrideThroughPageContext || true;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      images: this.images,
      allowOverrideThroughPageContext: this.allowOverrideThroughPageContext,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): ImageGalleryPageComponent {
    const res = new ImageGalleryPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
