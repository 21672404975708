import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {BaseObject} from '../base-object';
import {Link} from '../link';
import {ApogeeFile} from '../apogee-file';

export class ImageTileGridPageComponentItem extends BaseObject {
  public title: string;
  public link: Link;
  public image: ApogeeFile;

  constructor(el: any = {}) {
    super(el);

    this.title = el.title || '';
    this.link = Link.create(el.link);
    this.image = new ApogeeFile(el.image);
  }

  public applyStringTransformer(
    transformer: StringTransformer,
  ): ImageTileGridPageComponentItem {
    const res = new ImageTileGridPageComponentItem(this.toJson());

    res.title = transformer.transform(this.title);
    res.link = this.link.applyStringTransformer(transformer);

    return res;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      link: this.link.toJson(),
      image: this.image,
    };
  }
}

export class ImageTileGridPageComponent extends SectionPageComponent<
  ImageTileGridPageComponent,
  'imageTileGrid'
> {
  public tiles: ImageTileGridPageComponentItem[];

  constructor(el: any = {}) {
    super(el, 'imageTileGrid', {hasTitle: true, required: false});

    this.tiles = (el.tiles || []).map(
      (tile: any) => new ImageTileGridPageComponentItem(tile),
    );
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      tiles: this.tiles.map(tile => tile.toJson()),
    };
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): ImageTileGridPageComponent {
    const res = new ImageTileGridPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.tiles = this.tiles.map(tile =>
      tile.applyStringTransformer(transformer),
    );

    return res;
  }

  getSearchKeys(): string[] {
    return ['sectionTitle'];
  }
}
