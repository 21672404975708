import {StringTransformer} from './string-transformer';
import {PageComponent} from './page-component';
import {EMPTY_RICH_TEXT, RichText} from "../rich-text";

export class ImprintPageComponent extends PageComponent<
  ImprintPageComponent,
  'imprint'
> {
  public text: RichText;

  constructor(el: any = {}) {
    super(el, 'imprint');
    this.text = el.text ?? EMPTY_RICH_TEXT;
  }

  public override getSearchKeys(): string[] {
    return [];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): ImprintPageComponent {
    const res = new ImprintPageComponent(this.toJson());

    res.text.stringTransformer = transformer;

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      text: this.text,
    };
  }
}
