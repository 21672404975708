<div *ngIf="!userHasPermissions" class="overlay">
    <div class="content" *ngIf="!showRequestPermissions && !permissionsRequested">
        <h3 class="heading-1-font center">Keine Berechtigung</h3>
        <p class="body-font center">Du bist leider nicht berechtigt diese Anwendung zu verwenden. Bitte wende dich an
            deinen Vorgesetzten oder deinen Ansprechpartner beim Franchise.</p>
        <bryn-button-group>

            <bryn-button buttonLabel="Abmelden" (click)="logout()"></bryn-button>
            <bryn-button buttonLabel="Berechtigung Anfragen" buttonColor="neutral"
                         (click)="showRequestPermissions = true"></bryn-button>
        </bryn-button-group>
    </div>

    <div class="content" *ngIf="showRequestPermissions && !permissionsRequested">
        <h3 class="heading-1-font">Berechtigung anfragen</h3>
        <p class="body-font center">Wähle die Academy Website, auf die zu Zugriff erhalten möchtest.</p>

        <bryn-autocomplete
                class="autocomplete"
                *ngIf="websites"
                placeholder="Website wählen"
                [value]="website"
                (valueChange)="setWebsite($event)"
                [values]="websites"
                [mapValueToLabel]="websiteToLabel"
        ></bryn-autocomplete>

        <bryn-button-group>

            <bryn-button buttonLabel="Berechtigung Anfragen" buttonColor="primary" [buttonDisabled]="!website"
                         (click)="requestPermissions()"></bryn-button>
        </bryn-button-group>
    </div>

    <div class="content" *ngIf="showRequestPermissions && permissionsRequested">
        <h3 class="heading-1-font center">Berechtigung angefragt</h3>
        <p class="body-font center">Deine Anfrage ist eingegangen und wird nun überprüft.</p>
        <bryn-button-group>

            <bryn-button buttonLabel="Abmelden" (click)="logout()"></bryn-button>
        </bryn-button-group>
    </div>
</div>
