import {BaseWebsiteResource} from "./base-website-resource";
import {AbstractPageDefiningEntity} from "./abstract-page-defining-entity";
import {Menu} from "./menu";

export abstract class AbstractPageDefiningWebsiteResource extends BaseWebsiteResource implements AbstractPageDefiningEntity {
  private _pageUrl: string | undefined;
  private readonly uriSegment: string;
  private readonly parentMenu: Menu | null;

  protected constructor(resource: any) {
    super(resource);
    this.uriSegment = resource.uriSegment;
    this.parentMenu = resource.parentMenu ? new Menu(resource.parentMenu) : null;
    this._pageUrl = resource.pageUrl;
  }

  public get pageUrl(): string | undefined {
    if (this._pageUrl) {
      return this._pageUrl;
    }
    if (!this.parentMenu) {
      return undefined;
    }

    const segments = [this.uriSegment];

    let menu: Menu | null = this.parentMenu;
    while (menu) {
      segments.push(menu.uriSegment);
      menu = menu.parentMenu;
    }

    return ('/' + segments.reverse().join('/')).replace(/\/+/g, '/');
  }

  public set pageUrl(pageUrl: string | undefined) {
    this._pageUrl = pageUrl;
  }
}
