import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';
import {Link} from '../link';

export class CTAPageComponent extends SectionPageComponent<
  CTAPageComponent,
  'cta'
> {
  public cta: Link;
  public image: ApogeeFile;

  constructor(el: any = {}) {
    super(
      el,
      'cta',
      {hasTitle: true, required: true},
      {hasText: false, required: false},
    );
    this.cta = Link.create(el.cta);
    this.image = new ApogeeFile(el.image);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      cta: this.cta.toJson(),
      image: this.image,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle', 'cta.title'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): CTAPageComponent {
    const res = new CTAPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    res.cta = this.cta.applyStringTransformer(transformer);
    res.image = this.image;

    return res;
  }
}
