import {BaseWebsiteResource} from "./base-website-resource";
import {Course} from "./course";

export class CourseConfig extends BaseWebsiteResource {

  public courses: Course[];

  constructor(el: any = {}) {
    super(el);

    this.courses = (el.courses || []).map((course: any) => new Course(course));
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      courses: this.courses.map(course => course.toJson()),
    };
  }

  public override clone(): CourseConfig {
    return new CourseConfig({...this.toJson(), id: undefined});
  }
}
