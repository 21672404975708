import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';
import {StringTransformer} from './string-transformer';

export class NewsPageComponent extends SectionWithOptionalCtaPageComponent<
  NewsPageComponent,
  'news'
> {
  public grid: boolean;

  constructor(el: any = {}) {
    super(el, 'news');
    this.grid = el.grid || false;
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): NewsPageComponent {
    const res = new NewsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      grid: this.grid,
      ...parent,
    };
  }
}
