<bryn-global-wrapper>
    <bryn-menu-bar logoUrl="/assets/logo.svg">
        <!--    <bryn-application-switcher></bryn-application-switcher>-->

        <bryn-command-menu-trigger placeholder="Suchen..."></bryn-command-menu-trigger>
        <bryn-menu-bar-content>
            <bryn-side-menu *ngIf="menu" [menu]="menu">

                <bryn-side-menu-header *ngIf="canSwitchWebsites">
                    <bryn-autocomplete
                            *ngIf="websites"
                            label="Website wechseln"
                            [value]="website"
                            (valueChange)="setWebsite($event?.id)"
                            [values]="websites"
                            [mapValueToLabel]="websiteToLabel"
                    ></bryn-autocomplete>
                </bryn-side-menu-header>


                <bryn-side-menu-content>
                    <router-outlet></router-outlet>
                </bryn-side-menu-content>

                <bryn-side-menu-footer *ngIf="canPublish||!isEditingGlobalWebsite">
                    <bryn-button-group [disableLeadingButtonOffset]="true">
                        <bryn-button *ngIf="!isEditingGlobalWebsite" buttonLabel="Vorschau öffnen"
                                     (click)="previewWebsite()" primaryIcon="visibility"
                                     buttonStyle="ghost"></bryn-button>
                        <bryn-button
                                [attr.brynTooltip]="!canPublish ? 'Du hast keine Berechtigung die Website zu veröffentlichen.' : undefined"
                                [buttonDisabled]="!canPublish" [buttonLoading]="websitePublishing"
                                buttonLabel="Veröffentlichen" (click)="publishWebsite($event)"
                                primaryIcon="cloud_upload"></bryn-button>
                    </bryn-button-group>
                </bryn-side-menu-footer>
            </bryn-side-menu>
        </bryn-menu-bar-content>
        <bryn-account-menu
                [accountImage]="'https://ui-avatars.com/api/?background=f2f2f2&color=fabb02&name=' + user.name"
                [name]="user.name" [email]="user.email" [accountActions]="accountActions"></bryn-account-menu>
    </bryn-menu-bar>

</bryn-global-wrapper>
<app-no-permissions-overlay></app-no-permissions-overlay>
