import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';
import {BaseObject} from '../base-object';
import {SectionPageComponent} from './section-page-component';

export class CertificationsPageComponentItem extends BaseObject {
  public title: string;
  public description: string | undefined;
  public image: ApogeeFile;

  constructor(el: any = {}) {
    super(el);
    this.title = el.title || '';
    this.description = el.description;
    this.image = new ApogeeFile(el.image);
  }

  public applyStringTransformer(
    transformer: StringTransformer,
  ): CertificationsPageComponentItem {
    const res = new CertificationsPageComponentItem(this.toJson());
    res.title = transformer.transform(this.title);
    if (this.description !== undefined) {
      res.description = transformer.transform(this.description);
    }
    return res;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      description: this.description,
      image: this.image,
    };
  }
}

export class CertificationsPageComponent extends SectionPageComponent<
  CertificationsPageComponent,
  'certifications'
> {
  public certifications: CertificationsPageComponentItem[];

  constructor(el: any = {}) {
    super(
      el,
      'certifications',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );

    this.certifications = (el.certifications || []).map(
      (certification: any) =>
        new CertificationsPageComponentItem(certification),
    );
  }

  public override getSearchKeys(): string[] {
    return [];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): CertificationsPageComponent {
    const res = new CertificationsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.certifications = this.certifications.map(certification =>
      certification.applyStringTransformer(transformer),
    );

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      certifications: this.certifications.map(certification =>
        certification.toJson(),
      ),
    };
  }
}
