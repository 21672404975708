import {AbstractPageDefiningWebsiteResource} from './abstract-page-defining-website-resource';
import {EMPTY_RICH_TEXT, RichText} from './rich-text';
import {LicenseClassFact} from './license-class-fact';
import {ApogeeFile} from './apogee-file';
import {LicenseClassCategory} from './license-class-category';

export class LicenseClass extends AbstractPageDefiningWebsiteResource {
  public productId: string;
  public title: string;
  public displayTitle: string;
  public shortDescription: string;
  public description: RichText;
  public category: LicenseClassCategory | null;
  public facts: LicenseClassFact[];
  public icon: ApogeeFile;

  constructor(licenseClass: any = {}) {
    super(licenseClass);
    this.productId = licenseClass.productId ?? '';
    this.title = licenseClass.title ?? '';
    this.displayTitle = licenseClass.displayTitle ?? '';
    this.shortDescription = licenseClass.shortDescription ?? '';
    this.description = licenseClass.description ?? EMPTY_RICH_TEXT;
    this.category = licenseClass.category
      ? new LicenseClassCategory(licenseClass.category)
      : null;
    this.facts = (licenseClass.facts || []).map(
      (f: any) => new LicenseClassFact(f),
    );
    this.icon = new ApogeeFile(licenseClass.icon);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      productId: this.productId,
      title: this.title,
      displayTitle: this.displayTitle,
      shortDescription: this.shortDescription,
      description: this.description,
      category: this.category?.toJson(),
      facts: this.facts.map(f => f.toJson()),
      icon: this.icon,
    };
  }

  public override getLabel(): string {
    return `${this.title}`;
  }

  public override clone(): LicenseClass {
    return new LicenseClass({...this.toJson(), id: undefined});
  }
}
