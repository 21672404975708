import {PageComponent} from "./page-component";
import {Link} from "../link";
import {ApogeeFile} from "../apogee-file";
import {StringTransformer} from "./string-transformer";

export class PageHeroPageComponent extends PageComponent<PageHeroPageComponent, 'pageHero'> {
  public overline: string;
  public showTopJobBadgeInHero: boolean;
  public heading: string;
  public benefits: string[];
  public cta: Link;
  public image: ApogeeFile;
  public rating: number;
  public allowImageOverrideThroughPageContext: boolean;

  constructor(el: any = {}) {
    super(el, 'pageHero');
    this.overline = el.overline;
    this.showTopJobBadgeInHero = el.showTopJobBadgeInHero;
    this.heading = el.heading;
    this.benefits = (el.benefits || []);
    this.cta = Link.create(el.cta);
    this.image = new ApogeeFile(el.image);
    this.rating = el.rating;
    this.allowImageOverrideThroughPageContext = el.allowImageOverrideThroughPageContext || true;
  }

  public override getSearchKeys(): string[] {
    return ["overline", "heading", "benefits", "cta.title"];
  }

  public override applyStringTransformer(transformer: StringTransformer): PageHeroPageComponent {
    const res = new PageHeroPageComponent(this.toJson());

    res.overline = transformer.transform(this.overline);
    res.showTopJobBadgeInHero = this.showTopJobBadgeInHero;
    res.heading = transformer.transform(this.heading);
    res.benefits = this.benefits.map(benefit => transformer.transform(benefit));
    res.cta = this.cta.applyStringTransformer(transformer);
    res.image = this.image;
    res.rating = this.rating;
    res.allowImageOverrideThroughPageContext = this.allowImageOverrideThroughPageContext;

    return res;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      overline: this.overline,
      showTopJobBadgeInHero: this.showTopJobBadgeInHero,
      heading: this.heading,
      benefits: this.benefits,
      cta: this.cta.toJson(),
      image: this.image,
      rating: this.rating,
      allowImageOverrideThroughPageContext: this.allowImageOverrideThroughPageContext,
    }
  }
}
