import {Storable} from "./storable";

export class ApogeeFile implements Storable {
  private contentId: string | undefined;
  private filename: string;
  private sizeInBytes: number | undefined;
  private downloadUrl: string | undefined;
  private sha256FileHash: string | undefined;
  private changed = false;
  private _uploadFile: File | undefined;
  private type: string;

  constructor(r: any = {}) {
    if (!r) {
      r = {};
    }
    this.contentId = r.contentId;
    this.sha256FileHash = r.sha256FileHash;
    this.filename = r.filename || '';
    this.downloadUrl = r.downloadUrl;
    this.sizeInBytes = r.sizeInBytes;
    this.type = r.type || 'unknown/unknown';
    if (r.uploadFile) {
      console.log('upload file', r.uploadFile)
      this.setUploadFile(r.uploadFile);
    }
  }

  public getId(): string | undefined {
    return this.contentId;
  }

  public setUploadFile(file: File | undefined): void {
    this._uploadFile = file;
    this.changed = true;
    this.contentId = undefined;
    this.sha256FileHash = undefined;
  }
  public getUploadFile(): File | undefined {
    return this._uploadFile;
  }

  public get uploadFile(): File | undefined {
    return this._uploadFile;
  }

  public set uploadFile(value: File | undefined) {
    this.setUploadFile(value);
  }

  public setDownloadUrl(url: string): void {
    this.downloadUrl = url;
  }

  public getFilename(): string {
    return this.filename;
  }

  public getType(): string {
    return this.type;
  }

  public getSizeInBytes(): number {
    return this.sizeInBytes || 0;
  }

  public getDownloadUrl(): string {
    return this.downloadUrl || '';
  }

  public hasChanges(): boolean {
    return this.changed;
  }
}
