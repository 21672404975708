import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class SalestoolEntrypointPageComponent extends SectionPageComponent<
  SalestoolEntrypointPageComponent,
  'salestoolEntrypoint'
> {
  constructor(el: any = {}) {
    super(el, 'salestoolEntrypoint');
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): SalestoolEntrypointPageComponent {
    const res = new SalestoolEntrypointPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
