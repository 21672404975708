import {SectionPageComponent} from './section-page-component';
import {EMPTY_RICH_TEXT, RichText} from '../rich-text';
import {StringTransformer} from './string-transformer';

export class AccordionItem {
  public title: string;
  public body: RichText;

  applyStringTransformer(transformer: StringTransformer): AccordionItem {
    const res = new AccordionItem({});

    res.title = transformer.transform(this.title);
    res.body = {...this.body, stringTransformer: transformer};

    return res;
  }

  constructor(el: any = {}) {
    this.title = el.title || '';
    this.body = el.body ?? EMPTY_RICH_TEXT;
  }
}

export class AccordionPageComponent extends SectionPageComponent<
  AccordionPageComponent,
  'accordion'
> {
  public items: AccordionItem[];

  constructor(el: any = {}) {
    super(
      el,
      'accordion',
      {hasTitle: true, required: true},
      {hasText: true, required: false},
    );

    this.items = (el.items || []).map((item: any) => {
      return new AccordionItem(item);
    });
  }

  public override getSearchKeys(): string[] {
    return ['items.title', 'items.body.plainText', 'body.plainText'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): AccordionPageComponent {
    const res = new AccordionPageComponent(super.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    res.items = this.items.map(item =>
      item.applyStringTransformer(transformer),
    );

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      items: this.items,
    };
  }
}
