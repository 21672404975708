import {StringTransformer} from './string-transformer';
import {PageComponent} from './page-component';

export class BlogPostPageComponent extends PageComponent<
  BlogPostPageComponent,
  'blogPost'
> {
  constructor(el: any = {}) {
    super(el, 'blogPost', ['BlogPost']);
  }

  public override getSearchKeys(): string[] {
    return [];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): BlogPostPageComponent {
    const res = new BlogPostPageComponent(this.toJson());

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
    };
  }
}
