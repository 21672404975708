import {SortedBaseWebsiteResource} from "./base-website-resource";
import {LicenseClass} from "./license-class";
import {Location} from "./location";
import {ApogeeFile} from "./apogee-file";

export class TeamMember extends SortedBaseWebsiteResource {
  public name: string;
  public position: string;
  public contactPhone: string;
  public contactEmail: string;
  public contactMobile: string;
  public languages: string[];
  public vita: string;
  public image: ApogeeFile;
  public licenseClasses: LicenseClass[];
  public locations: Location[];


  constructor(teammember: any = {}) {
    super(teammember);
    this.name = teammember.name || "";
    this.position = teammember.position || "";
    this.contactPhone = teammember.contactPhone || "";
    this.contactEmail = teammember.contactEmail || "";
    this.contactMobile = teammember.contactMobile || "";
    this.languages = teammember.languages || [];
    this.vita = teammember.vita || "";
    this.image = new ApogeeFile(teammember.image);
    this.licenseClasses = (teammember.licenseClasses || []).map((c: any) => new LicenseClass(c));
    this.locations = (teammember.locations || []).map((c: any) => new Location(c));
  }


  public override toJson(): any {
    return {
      ...super.toJson(),
      name: this.name,
      position: this.position,
      contactPhone: this.contactPhone,
      contactEmail: this.contactEmail,
      contactMobile: this.contactMobile,
      languages: this.languages,
      vita: this.vita,
      image: this.image,
      licenseClasses: this.licenseClasses.map(({id}) => ({id})),
      locations: this.locations.map(({id}) => ({id})),
    };
  }

  public override getSearchIndex(): string[] {
    return [
      ...super.getSearchIndex(),
      this.name,
      this.position,
      this.contactPhone,
      this.contactEmail,
      this.contactMobile,
      ...this.languages,
      this.vita,
      ...this.licenseClasses.reduce((previousValue: string[], currentValue) => {
        return [...previousValue, ...currentValue.getSearchIndex()];
      }, []),
      ...this.locations.reduce((previousValue: string[], currentValue) => {
        return [...previousValue, ...currentValue.getSearchIndex()];
      }, []),
    ]
  }

  public override clone(): TeamMember {
    return new TeamMember({...this.toJson(), id: undefined});
  }
}
