export class VideoModel {
  private id: string | undefined;
  private changed = false;
  private uploadFile: File | undefined;
  private downloadUrlMp4: string | undefined;
  private previewDownloadUrlMp4: string | undefined;
  private downloadUrlWebm: string | undefined;
  private previewDownloadUrlWebm: string | undefined;

  constructor(r: any = {}) {
    if (!r) {
      r = {};
    }
    this.id = r.id;
    this.downloadUrlMp4 = r.downloadUrlMp4;
    this.previewDownloadUrlMp4 = r.previewDownloadUrlMp4;
    this.downloadUrlWebm = r.downloadUrlWebm;
    this.previewDownloadUrlWebm = r.previewDownloadUrlWebm;
  }

  public getId(): string | undefined {
    return this.id;
  }

  public setUploadFile(file: File | undefined): void {
    this.uploadFile = file;
    this.changed = true;
  }
  public getUploadFile(): File | undefined {
    return this.uploadFile;
  }

  public setDownloadUrlWebm(url: string): void {
    this.downloadUrlWebm = url;
  }

  public setPreviewDownloadUrlWebm(url: string): void {
    this.previewDownloadUrlWebm = url;
  }

  public setDownloadUrlMp4(url: string): void {
    this.downloadUrlMp4 = url;
  }

  public setPreviewDownloadUrlMp4(url: string): void {
    this.previewDownloadUrlMp4 = url;
  }

  public getDownloadUrlWebm(): string {
    return this.downloadUrlWebm || '';
  }

  public getPreviewDownloadUrlWebm(): string {
    return this.previewDownloadUrlWebm || '';
  }

  public getDownloadUrlMp4(): string {
    return this.downloadUrlMp4 || '';
  }

  public getPreviewDownloadUrlMp4(): string {
    return this.previewDownloadUrlMp4 || '';
  }

  public hasChanges(): boolean {
    return this.changed;
  }
}
