import {globalWebsite, Website} from './website';

export class UserPrivilege {
  public id: string;
  public name: string;

  constructor(userPrivilege: any = {}) {
    this.id = userPrivilege.id || '';
    this.name = userPrivilege.name || '';
  }
}

export class UserRole {
  public id: string;
  public name: string;
  public privileges: UserPrivilege[];

  constructor(userRole: any = {}) {
    this.id = userRole.id || '';
    this.name = userRole.name || '';
    this.privileges = (userRole.privileges || []).map(
      (privilege: any) => new UserPrivilege(privilege),
    );
  }

  public toJson(): any {
    return {
      id: this.id,
    };
  }
}

export class User {
  public id: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public oauthSubject: string;
  public roles: UserRole[];
  public website?: Website;
  public websiteId: string | null;
  public privileges: UserPrivilege[];
  public readonly canSwitchWebsites: boolean = false;
  public readonly canPublish: boolean = false;

  constructor(user: any = {}) {
    this.id = user.id || '';
    this.email = user.email || '';
    this.firstName = user.firstName || '';
    this.lastName = user.lastName || '';
    this.oauthSubject = user.oauthSubject || '';
    this.roles = (user.roles || []).map((role: any) => new UserRole(role));
    this.website = user.website ? new Website(user.website) : globalWebsite;
    this.websiteId = user.websiteId || null;
    this.privileges = (user.privileges || []).map(
      (privilege: any) => new UserPrivilege(privilege),
    );
    this.canSwitchWebsites = this.hasPrivilege('SWITCH_WEBSITES');
    this.canPublish = this.hasPrivilege('PUBLISH');
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public hasPrivilege(privilegeName: string): boolean {
    return this.privileges.some(privilege => privilege.name === privilegeName);
  }

  public toJson(): any {
    return {
      id: this.id,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      roles: this.roles.map(role => role.toJson()),
    };
  }
}
