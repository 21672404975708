import {SectionPageComponent} from './section-page-component';
import {PageComponentType} from './page-component-type';
import {Link} from '../link';

export abstract class SectionWithOptionalCtaPageComponent<
  Self extends SectionPageComponent<Self, T>,
  T extends PageComponentType,
> extends SectionPageComponent<Self, T> {
  public readonly hasOptionalCta = true;
  public cta: Link;

  protected constructor(
    el: any = {},
    type: T,
    title:
      | {hasTitle: true; required: boolean}
      | {hasTitle: false; required: false} = {hasTitle: true, required: true},
    text:
      | {hasText: true; required: boolean}
      | {hasText: false; required: false} = {hasText: true, required: false},
  ) {
    super(el, type, title, text);
    this.cta = Link.create(el.cta);
  }

  override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      cta: this.cta.toJson(),
    };
  }
}
