import {BaseObject} from "./base-object";

export abstract class BaseWebsiteResource extends BaseObject {
  public owner: any;
  public global: boolean = false;

  protected constructor({owner, global, ...props}: any = {}) {
    super(props);
    this.owner = owner;
    this.global = !!global;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      owner: this.owner,
    }
  }

  public getSearchIndex(): string[] {
    return [
      this.getLabel(),
    ];
  }

  getLabel(): string {
    return `Resource ${this.id}`
  }

  public get label() {
    return this.getLabel();
  }

  public abstract clone(): BaseWebsiteResource;
}

export abstract class SortedBaseWebsiteResource extends BaseWebsiteResource {
  public sortOrder: number;

  protected constructor({sortOrder, ...props}: any = {}) {
    super(props);
    this.sortOrder = sortOrder;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      sortOrder: this.sortOrder,
    }
  }
}
