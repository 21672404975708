import {BaseWebsiteResource} from './base-website-resource';
import {Menu} from './menu';
import {PageComponent, PageComponentFactory} from './page-components';

const types = [
  'Location',
  'BlogPost',
  'LicenseClass',
  'LicenseClassCategory',
  'Course',
  'NewsItem',
] as const;

export const abstractPageTypes = [...types];

export type AbstractPageType = (typeof types)[number];

export class AbstractPage extends BaseWebsiteResource {
  public pageType: AbstractPageType;
  public parentMenu: Menu | null;
  public components: PageComponent<any, any>[];
  public sortOrder: number;

  constructor(el: any = {}) {
    super(el);
    this.pageType = el.pageType || 'Location';
    this.parentMenu = el.parentMenu ? new Menu(el.parentMenu) : null;
    this.sortOrder = el.sortOrder || 0;
    const isPageComponent = (
      component: PageComponent<any, any> | undefined,
    ): component is PageComponent<any, any> => !!component;
    this.components = (el.components || [])
      .map((c: any) => new PageComponentFactory().create({...c, global: this.global}))
      .filter(isPageComponent);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      pageType: this.pageType,
      sortOrder: this.sortOrder,
      parentMenu: this.parentMenu?.toJson() || null,
      components: this.components.map(component => component.toJson()),
    };
  }

  public override clone(): AbstractPage {
    return new AbstractPage({
      pageType: this.pageType,
      sortOrder: this.sortOrder,
      parentMenu: this.parentMenu?.toJson() || null,
      components: this.components.map(component => component.clone().toJson()),
    });
  }
}
