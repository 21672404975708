import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {RichText} from '../rich-text';

export class RichTextPageComponent extends SectionPageComponent<
  RichTextPageComponent,
  'richText'
> {
  public singleColumn: boolean;

  constructor(el: any = {}) {
    super(
      el,
      'richText',
      {hasTitle: true, required: false},
      {hasText: true, required: true},
    );

    this.singleColumn = el.singleColumn ?? true;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      singleColumn: this.singleColumn,
    };
  }
  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new RichTextPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }

  getSearchKeys(): string[] {
    return [];
  }
}
