import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class ContactPageComponent extends SectionPageComponent<
  ContactPageComponent,
  'contact'
> {
  constructor(el: any = {}) {
    super(el, 'contact', {hasTitle: true, required: false});
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
    };
  }

  public override getSearchKeys(): string[] {
    return [
      'sectionTitle',
      'certifications.title',
      'certifications.description',
    ];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): ContactPageComponent {
    return new ContactPageComponent(this.toJson());
  }
}
