import {StringTransformer} from './page-components';
import {Menu} from './menu';
export abstract class Link {
  public title: string;

  protected constructor(el: any = {}) {
    if (!el) {
      el = {};
    }
    this.title = el.title || '';
  }

  public abstract applyStringTransformer(transformer: StringTransformer): Link;

  public abstract toJson(): any;

  public static create(data: any): Link {
    if (!data || data['useNoLink']) {
      return new NoopLink();
    } else if (data['useSmartLink']) {
      return new SmartLink(data);
    } else if (data['referencedMenu']) {
      return new InternalLink(data);
    } else {
      return new ExternalLink(data);
    }
  }
}

export class NoopLink extends Link {
  public readonly useNoLink = true;
  override applyStringTransformer(transformer: StringTransformer): NoopLink {
    return this;
  }

  override toJson(): any {
    return {
      useNoLink: this.useNoLink,
    };
  }

  constructor() {
    super();
  }
}

export class SmartLink extends Link {
  public readonly useSmartLink = true;

  override applyStringTransformer(transformer: StringTransformer): SmartLink {
    const res = new SmartLink();

    res.title = transformer.transform(this.title);

    return res;
  }

  override toJson(): any {
    return {
      title: this.title,
      referencedMenu: null,
      externalLink: null,
      useSmartLink: this.useSmartLink,
    };
  }

  constructor(el: any = {}) {
    super(el);
  }
}

export class InternalLink extends Link {
  public referencedMenu: Menu;

  override applyStringTransformer(
    transformer: StringTransformer,
  ): InternalLink {
    const res = new InternalLink();

    res.referencedMenu = this.referencedMenu;

    res.title = transformer.transform(this.title);

    return res;
  }

  override toJson(): any {
    return {
      title: this.title,
      referencedMenu: this.referencedMenu?.toJson() || null,
      externalLink: null,
    };
  }

  constructor(el: any = {}) {
    super(el);
    if (!el) {
      el = {};
    }

    this.referencedMenu = el.referencedMenu ? new Menu(el.referencedMenu) : undefined as unknown as Menu;
  }
}

export class ExternalLink extends Link {
  public externalLink: string;

  override applyStringTransformer(
    transformer: StringTransformer,
  ): ExternalLink {
    const res = new ExternalLink();

    res.externalLink = this.externalLink;

    res.title = transformer.transform(this.title);

    return res;
  }

  override toJson(): any {
    return {
      title: this.title,
      referencedMenu: null,
      externalLink: this.externalLink,
    };
  }

  constructor(el: any = {}) {
    super(el);
    if (!el) {
      el = {};
    }

    this.externalLink = el.externalLink ?? '#';
  }
}
