import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';
import {StringTransformer} from './string-transformer';

export class LocationsPageComponent extends SectionWithOptionalCtaPageComponent<
  LocationsPageComponent,
  'locations'
> {
  public grid: boolean;
  constructor(el: any = {}) {
    super(
      el,
      'locations',
      {hasTitle: true, required: true},
      {hasText: true, required: false},
    );
    this.grid = el.grid;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      grid: this.grid,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): LocationsPageComponent {
    const res = new LocationsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }
}
