import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StorageService} from "@shared/services/storage-service";
import {ApogeeFile} from "@shared/model/apogee-file";

@Injectable({
  providedIn: 'root'
})
export class ApogeeFileApiService extends StorageService<ApogeeFile> {
  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/apogee-file', (obj: any) => new ApogeeFile(obj), (obj: any): obj is ApogeeFile => obj instanceof ApogeeFile)
  }
}
