import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class QuickFactsPageComponentFact {
  public label: string;
  public value: string;

  constructor(el: any = {}) {
    this.label = el.label;
    this.value = el.value;
  }

  public applyStringTransformer(transformer: StringTransformer) {
    const res = new QuickFactsPageComponentFact(this);

    res.value = transformer.transform(this.value);
    res.label = transformer.transform(this.label);

    return res;
  }
}

export class QuickFactsPageComponent extends SectionPageComponent<
  QuickFactsPageComponent,
  'quickFacts'
> {
  public facts: QuickFactsPageComponentFact[];

  constructor(el: any = {}) {
    super(
      el,
      'quickFacts',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );

    this.facts = (el.facts || []).map((fact: any) => {
      return new QuickFactsPageComponentFact(fact);
    });
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle', 'facts.label', 'facts.value'];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new QuickFactsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.facts = this.facts.map(fact =>
      fact.applyStringTransformer(transformer),
    );

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      facts: this.facts,
    };
  }
}
