import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class TodoListPageComponent extends SectionPageComponent<
  TodoListPageComponent,
  'todoList'
> {
  public todos: string[];

  constructor(el: any = {}) {
    super(
      el,
      'todoList',
      {hasTitle: true, required: true},
      {hasText: true, required: false},
    );

    this.todos = el.todos || [];
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      todos: this.todos,
    };
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): TodoListPageComponent {
    const res = new TodoListPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};
    res.todos = this.todos.map(todo => transformer.transform(todo));

    return res;
  }

  getSearchKeys(): string[] {
    return [];
  }
}
