export class LicenseClassFact {
  public title: string;
  public value: string;

  constructor(licenseClassFact: any = {}) {
    this.title = licenseClassFact.title ?? "";
    this.value = licenseClassFact.value ?? "";
  }

  public toJson(): any {
    return {
      title: this.title,
      value: this.value,
    };
  }
}
