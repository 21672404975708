import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class KeyBenefitsPageComponent extends SectionPageComponent<
  KeyBenefitsPageComponent,
  'keyBenefits'
> {
  public benefits: string[];

  constructor(el: any = {}) {
    super(el, 'keyBenefits', {hasTitle: true, required: false}, {hasText: false, required: false});

    this.benefits = el.benefits || [];
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle', 'benefits'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): KeyBenefitsPageComponent {
    const res = new KeyBenefitsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.benefits = this.benefits.map(benefit => transformer.transform(benefit));

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      benefits: this.benefits,
    };
  }
}
