import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class SalestoolIntensiveCourseOverviewPageComponent extends SectionPageComponent<
  SalestoolIntensiveCourseOverviewPageComponent,
  'salestoolIntensiveCourseOverview'
> {
  constructor(el: any = {}) {
    super(el, 'salestoolIntensiveCourseOverview', {
      hasTitle: true, required: false
    },{hasText:false, required: false});
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): SalestoolIntensiveCourseOverviewPageComponent {
    const res = new SalestoolIntensiveCourseOverviewPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
