import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Website} from '@shared/model';
import {map, Observable, of, switchMap} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ApogeeFileApiService} from "@shared/services/apogee-file-api.service";

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  constructor(
    private httpClient: HttpClient,
    private apogeeFileService: ApogeeFileApiService,
  ) {}

  public listAll(): Observable<Website[]> {
    return this.httpClient.get(`${environment.apiUrl}/api/website`).pipe(
      map((websites: any) => {
        return (websites || []).map((website: any) => new Website(website));
      }),
    );
  }

  public get(resourceId: string): Observable<Website> {
    return this.httpClient
      .get(`${environment.apiUrl}/api/website/${resourceId}`)
      .pipe(map((website: any) => new Website(website)));
  }

  public create(resource: Website): Observable<Website> {
    return of(resource.toJson()).pipe(
      switchMap((r: any) => this.apogeeFileService.saveBaseWebsiteResourceChildren(r)),
      switchMap(r => {
        return this.httpClient
          .post(`${environment.apiUrl}/api/website`, r)
          .pipe(map((website: any) => new Website(website)));
      }),
    );
  }

  public update(resource: Website): Observable<Website> {
    return of(resource.toJson()).pipe(
      switchMap((r: any) => this.apogeeFileService.saveBaseWebsiteResourceChildren(r)),
      switchMap(r => {
        return this.httpClient
          .put(`${environment.apiUrl}/api/website`, r)
          .pipe(map((website: any) => new Website(website)));
      }),
    );
  }

  public publishPreviews(): Observable<void> {
    return this.httpClient
      .post(`${environment.apiUrl}/api/publish`, {})
      .pipe(map(() => {}));
  }

  public delete(resourceId: string): Observable<void> {
    return this.httpClient
      .delete(`${environment.apiUrl}/api/website/${resourceId}`)
      .pipe(map(() => {}));
  }
}
