import {AbstractPageDefiningWebsiteResource} from "./abstract-page-defining-website-resource";
import {EMPTY_RICH_TEXT, RichText} from './rich-text';
import {ApogeeFile} from './apogee-file';

export class NewsItem extends AbstractPageDefiningWebsiteResource {
  public title: string;
  public text: RichText;
  public date: Date;
  public heroImage: ApogeeFile;

  constructor(newsItem: any = {}) {
    super(newsItem);
    this.title = newsItem.title ?? '';
    this.text = newsItem.text ?? EMPTY_RICH_TEXT;
    this.date = newsItem.date ? new Date(newsItem.date) : new Date();
    this.heroImage = new ApogeeFile(newsItem.heroImage);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      text: this.text,
      date: this.date,
      heroImage: this.heroImage,
    };
  }

  public override getSearchIndex(): string[] {
    return [...super.getSearchIndex(), this.title, this.text.plainText];
  }

  override getLabel(): string {
    return this.title;
  }

  public override clone(): NewsItem {
    return new NewsItem({...this.toJson(), id: undefined});
  }
}
