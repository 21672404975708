import {StringTransformer} from "./page-components/string-transformer";

export interface RichText {
  state: any;
  plainText: string;
  stringTransformer?: StringTransformer;
}
export const EMPTY_RICH_TEXT: RichText = {
  "state": {
    "root": {
      "children": [
        {
          "children": [],
          "direction": null,
          "format": "",
          "indent": 0,
          "type": "paragraph",
          "version": 1
        }
      ],
      "direction": null,
      "format": "",
      "indent": 0,
      "type": "root",
      "version": 1
    }
  },
  "plainText": ""
};

