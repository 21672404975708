import {AbstractPageDefiningWebsiteResource} from "./abstract-page-defining-website-resource";

export class LicenseClassCategory extends AbstractPageDefiningWebsiteResource {
  public title: string;

  constructor(licenseClass: any = {}) {
    super(licenseClass);
    this.title = licenseClass.title ?? '';
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
    };
  }

  public override getLabel(): string {
    return `${this.title}`;
  }

  public override clone(): LicenseClassCategory {
    return new LicenseClassCategory({...this.toJson(), id: undefined});
  }
}
