import {SectionPageComponent} from './section-page-component';
import {EMPTY_RICH_TEXT, RichText} from '../rich-text';
import {StringTransformer} from './string-transformer';

export class RichTextTilesTile {
  public text: RichText;
  public title: string;

  constructor(el: any = {}) {
    this.text = el.text ?? EMPTY_RICH_TEXT;
    this.title = el.title;
  }

  public applyStringTransformer(transformer: StringTransformer) {
    const res = new RichTextTilesTile(this);

    res.title = transformer.transform(this.title);
    res.text.stringTransformer = transformer;

    return res;
  }
}

export class RichTextTilesPageComponent extends SectionPageComponent<
  RichTextTilesPageComponent,
  'richTextTiles'
> {
  public tiles: RichTextTilesTile[];

  public override getSearchKeys(): string[] {
    return ['tiles.text.plainText', 'tiles.title', 'sectionTitle'];
  }

  constructor(el: any = {}) {
    super(el, 'richTextTiles');

    this.tiles = (el.tiles || []).map(
      (tile: any) => new RichTextTilesTile(tile),
    );
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      tiles: this.tiles,
    };
  }
  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new RichTextTilesPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.tiles = this.tiles.map(tile =>
      tile.applyStringTransformer(transformer),
    );

    return res;
  }
}
