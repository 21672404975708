const types = [
    'pageHero',
    'accordion',
    'reviewsMarquee',
    'steps',
    'table',
    'blogPosts',
    'kpis',
    'quickFacts',
    'teamMembers',
    'partners',
    'vehicles',
    'narrowRichText',
    'blogPost',
    'locationMap',
    'locations',
    'locationsOverview',
    'imageGallery',
    'contact',
    'infomaterial',
    'downloads',
    'cta',
    'navigationTiles',
    'smallPageHero',
    'richText',
    'richTextTiles',
    'subpageOverview',
    'salestoolIntensiveCourseOverview',
    'illustrationTiles',
    'textWithImage',
    'testimonial',
    'timetable',
    'slot',
    'todoList',
    'guarantees',
    'imageTileGrid',
    'courseRegistration',
    'salestoolEntrypoint',
    'licenseClassInfo',
    'individualDrivingLicensePosterSection',
    'news',
    'newsItemContent',
    'certifications',
    'keyBenefits',
    'courseList',
    'imprint',
] as const;

export const pageComponentTypes = [...types];

export type PageComponentType = (typeof pageComponentTypes)[number];

const pageComponentTypeLabels: {
    [key in PageComponentType]: {
        title: string,
        description?: string
        keywords?: string[]
    }
} = {
    pageHero: {title: 'Seiten-Hero', description: "Seiten-Hero mit Hintergrund-Bild/-Video. Sollte nur als erste Sektion einer Seite verwendet werden.", keywords: ["header"]},
    courseRegistration: {title: 'Kurs-Anmeldung', description: "Anmeldeformular eines Kurses. Wird nur angezeigt, wenn der Seitenkontext eines Kurses gesetzt ist.", keywords: ["registration", "course", "anfrage", "kurs"]},
    accordion: {title: 'Ausklappliste', description: "Liste mit ausklappbaren Elementen. Wird z.B. als FAQ verwendet.", keywords: ["faq", "akkordeon"]},
    reviewsMarquee: {title: 'Reviews', description: "Von Google geladene Kundenbewertungen, gefiltert nach Sternebewertung.", keywords: ["reviews", "kundenbewertung", "rezensionen"]},
    steps: {title: 'Nummerierte Schritte', description: "Liste von nummerierten Schritten. Wird verwendet um Nutzern z.B. die nächsten Schritte nach einer Anmeldung anzuzeigen.", keywords: ["schritte", "steps"]},
    table: {title: 'Tabelle', description: "Tabelle mit beliebig vielen Zeilen und Spalten. Optimal zum Vergleich verschiedener Angebote oder Klassen.", keywords: ["tabelle", "table", "vergleich"]},
    blogPosts: {title: 'Blog-Beiträge', description: "Liste von Blog-Beiträgen.", keywords: ["blog", "beiträge"]},
    kpis: {title: 'KPIs', description: "Liste von Key-Performance-Indikatoren.", keywords: ["kpi", "key performance indicator"]},
    quickFacts: {title: 'Schnelle Fakten', description: "Kompakte Fakten die z.B. für eine Übersicht einer Führerscheinklasse dient.", keywords: ["fakten", "übersicht"]},
    teamMembers: {title: 'Team', description: "Liste oder Raster von Team-Mitgliedern.", keywords: ["team", "mitarbeiter", "fahrlehrer", "büro", "mitglieder"]},
    partners: {title: 'Partner', description: "Liste oder Raster von Partnern.", keywords: ["partner"]},
    vehicles: {title: 'Fahrzeuge', description: "Liste von Fahrzeugen.", keywords: ["fahrzeuge", "autos", "motorräder"]},
    narrowRichText: {title: 'Schmaler Text', description: "Text der in einer schmalen Spalte angezeigt wird. Optimal für Rechtstexte.", keywords: ["text", "schmal", "rechtstext", "impressum"]},
    courseList: {title: 'Kurs-Liste', description: "Liste von Kursen.", keywords: ["kurse", "kursliste"]},
    blogPost: {title: 'Blog-Beitrag', description: "Inhalt eines einzelnen Blog-Beitrags. Kann nur verwendet werden, wenn der Seitenkontext eines Blog-Beitrags gesetzt ist.", keywords: ["blog", "beitrag"]},
    locationMap: {title: 'Standort-Informationen', description: "Karte, Öffnungszeigen und Kontaktdaten eines Standorts. Kann nur verwendet werden wenn der Seitenkontext eines Standorts gesetzt ist.", keywords: ["standorte", "karte", "öffnungszeiten", "kontakt"]},
    locations: {title: 'Standorte', description: "Liste oder Raster aller Standorte, sortiert nach Entfernung zum Nutzer.", keywords: ["standorte"]},
    locationsOverview: {title: 'Standorte-Übersicht', description: "Karte aller Standorte.", keywords: ["standorte", "standort", "übersicht", "karte"]},
    imageGallery: {title: 'Bildergalerie', description: "Galerie von Bildern.", keywords: ["bilder", "galerie"]},
    contact: {title: 'Kontakt', description: "Kontaktformular und Kontaktdaten.", keywords: ["kontakt", "kontaktformular", "anfrage"]},
    infomaterial: {title: 'Infomaterial', description: "Infomaterial-Anfrage.", keywords: ["infomaterial", "anfrage"]},
    downloads: {title: 'Downloads', description: "Liste von Downloads.", keywords: ["downloads", "herunterladen", "datei"]},
    cta: {title: 'Call to Action', description: "Call to Action Button.", keywords: ["cta", "call to action"]},
    navigationTiles: {title: 'Navigationskacheln', description: "Liste von Kacheln die auf andere Seiten verlinken.", keywords: ["navigation", "kacheln", "kachel"]},
    smallPageHero: {title: 'Kleiner Seiten-Hero', description: "Kleiner Seiten-Hero ohne Hintergrund-Bild/-Video. Sollte wenn nur als erste Sektion einer Seite verwendet werden.", keywords: ["header", "klein"]},
    richText: {title: 'Text', description: "Formatierbarer Text mit beliebigem Inhalt.", keywords: ["text"]},
    richTextTiles: {title: 'Textkacheln', description: "Textkacheln zur Darstellung gleich-wichtiger Textblöcke nebeneinander.", keywords: ["text", "kacheln"]},
    illustrationTiles: {title: 'Textkacheln mit Illustration', description: "Textkacheln mit Illustration.", keywords: ["text", "kacheln", "illustration"]},
    textWithImage: {title: 'Text mit Bild', description: "Text mit Bild.", keywords: ["text", "bild"]},
    testimonial: {title: 'Testimonial', description: "Darstellung einer Kundenbewertung.", keywords: ["testimonial", "kundenbewertung"]},
    timetable: {title: 'Stundenplan', description: "Theorie-Unterrichts-Plan. Wenn der Seitenkontext eines Standorts gewählt ist, werden die Zeiten des Standorts angezeigt. Ansonsten kann der Nutzer den Standort wählen.", keywords: ["stundenplan", "theorieunterricht", "zeiten"]},
    slot: {title: 'Slot', description: "Platzhalter für ein anderes Element, welches der Franchisenehmer selbst bearbeiten kann.", keywords: ["platzhalter", "slot"]},
    todoList: {title: 'To-Do-Liste', description: "Liste von To-Dos.", keywords: ["todo", "liste"]},
    guarantees: {title: 'Garantien', description: "Liste von Garantien.", keywords: ["garantien"]},
    imageTileGrid: {title: 'Bildkacheln', description: "Bildkacheln, welche als Link zu anderen Seiten/Aktionen verwendet werden kann.", keywords: ["bilder", "kacheln"]},
    salestoolEntrypoint: {title: 'Verkaufstool-Einstiegspunkt', description: "Einstiegspunkt für das Verkaufstool.", keywords: ["verkaufstool", "einstiegspunkt"]},
    licenseClassInfo: {title: 'Führerscheinklassen Info', description: "Übersicht und Informationen zu einzelnen Führerscheinklassen.", keywords: ["führerscheinklassen", "info"]},
    individualDrivingLicensePosterSection: {title: 'Individuelle Führerscheinklassen', description: "Individuelle Führerscheinklassen Grafik-Banner.", keywords: ["führerscheinklassen", "individuell"]},
    news: {title: 'News', description: "Liste von News-Beiträgen.", keywords: ["news", "nachrichten"]},
    newsItemContent: {title: 'News-Inhalt', description: "Inhalt eines einzelnen News-Beitrags. Kann nur verwendet werden, wenn der Seitenkontext eines News-Beitrags gesetzt ist.", keywords: ["news", "beitrag"]},
    certifications: {title: 'Zertifizierungen', description: "Liste von Zertifizierungen mit Bild und Text.", keywords: ["zertifizierungen"]},
    keyBenefits: {title: 'Vorteile', description: "Liste von Vorteilen.", keywords: ["vorteile"]},
    subpageOverview: {title: 'Unterseiten-Übersicht', description: "Liste von Unterseiten der Aktuellen Seite mit Bild der Unterseite. Wenn es keine Unterseiten gibt, wird die Sektion nicht angezeigt.", keywords: ["unterseiten", "übersicht"]},
    salestoolIntensiveCourseOverview: {title: 'Verkaufstool Intensivkurs-Übersicht', description: "Übersicht der im Verkaufstool angebotenen Intensivkurse mit direktem Absprung zum und Vorauswahl im Verkaufstool.", keywords: ["verkaufstool", "intensivkurs", "übersicht"]},
    imprint: {title: 'Impressum', description: "Impressum des Unternehmens.", keywords: ["impressum", "rechtstext"]},
};

export const getPageComponentLabel = (type: PageComponentType): {
    title: string,
    description?: string,
    keywords?: string[],
} => {
    return pageComponentTypeLabels[type];
};
