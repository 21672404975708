import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom, map, Observable, of, Subject} from "rxjs";
import {Storable} from "@shared/model/storable";


export abstract class StorageService<T extends Storable> {

  private readonly apiUrl: string = environment.apiUrl;
  protected constructor(
    private httpClient: HttpClient,
    private readonly route: string,
    private readonly tConstructor: (res: any) => T,
    private readonly isT: (res: any) => res is T,
  ) {
  }

  public create(resource: Storable): Observable<Storable | null> {
    const formData = new FormData();

    const file = resource.getUploadFile();
    if (file) {
      formData.append("file", file);
    } else {
      return of(null)
    }

    return this.httpClient.post(`${this.apiUrl}${this.route}`, formData, {}).pipe(
      map(this.tConstructor)
    )
  }

  private save(image: Storable): Promise<Storable | null> | undefined {
    if (image.hasChanges()) {
      return firstValueFrom(this.create(image));
    } else if (!image.getId() && !image.hasChanges()) {
      // no image
      return Promise.resolve(null);
    }
    return undefined;
  }


  public saveBaseWebsiteResourceChildren<B extends {}>(resource: B): Observable<B> {
    const requests: Promise<any>[] = [];

    for (const resourceKey in resource) {
      if (resource.hasOwnProperty(resourceKey)) {
        if (this.isT(resource[resourceKey])) {
          const res = this.save(resource[resourceKey] as T);
          if (res) {
            requests.push(res.then(image => {
              //@ts-ignore
              resource[resourceKey] = image;
            }));
          }
        } else if (Array.isArray(resource[resourceKey])) {
          (resource[resourceKey] as any[]).forEach((el: any, index: number) => {
            if (this.isT(el)) {
              const res = this.save(el);
              if (res) {
                requests.push(res.then(image => {
                  //@ts-ignore
                  resource[resourceKey][index] = image;
                }));
              }
            } else if (typeof el === "object" && el !== null) {
              requests.push(firstValueFrom(this.saveBaseWebsiteResourceChildren(el as any)))
            }
          })
        } else if (typeof resource[resourceKey] === "object" && resource[resourceKey] !== null) {
          requests.push(firstValueFrom(this.saveBaseWebsiteResourceChildren(resource[resourceKey] as any)))
        }
      }
    }

    const subject = new Subject<B>();

    Promise.all(requests).then(() => {
      subject.next(resource);
      subject.complete();
    })

    return subject;
  }
}
