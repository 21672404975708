import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';
import {StringTransformer} from './string-transformer';
import {BlogPostCategory} from '../blog-post';

export class BlogPostsPageComponent extends SectionWithOptionalCtaPageComponent<
  BlogPostsPageComponent,
  'blogPosts'
> {
  public category: BlogPostCategory;
  public grid: boolean;

  constructor(el: any = {}) {
    super(el, 'blogPosts');

    this.category = el.category || 'OTHER';
    this.grid = el.grid || false;
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): BlogPostsPageComponent {
    const res = new BlogPostsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      category: this.category,
      grid: this.grid,
    };
  }
}
