import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class LocationMapPageComponent extends SectionPageComponent<
  LocationMapPageComponent,
  'locationMap'
> {
  constructor(el: any = {}) {
    super(
      el,
      'locationMap',
      {hasTitle: true, required: true},
      {hasText: false, required: false},
      ['Location'],
    );
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
    };
  }
  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): LocationMapPageComponent {
    const res = new LocationMapPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
