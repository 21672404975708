import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class PartnersPageComponent extends SectionPageComponent<
  PartnersPageComponent,
  'partners'
> {
  public grid: boolean;
  constructor(el: any = {}) {
    super(el, 'partners');
    this.grid = el.grid;
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      grid: this.grid,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new PartnersPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
