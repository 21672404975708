import {PageComponent} from './page-component';
import {StringTransformer} from './string-transformer';

export class PageHeroSmallPageComponent extends PageComponent<
  PageHeroSmallPageComponent,
  'smallPageHero'
> {
  public overline?: string;
  public heading: string;

  constructor(el: any = {}) {
    super(el, 'smallPageHero');
    this.overline = el.overline;
    this.heading = el.heading;
  }

  public override getSearchKeys(): string[] {
    return ['overline', 'heading'];
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      overline: this.overline,
      heading: this.heading,
    };
  }

  applyStringTransformer(
    transformer: StringTransformer,
  ): PageHeroSmallPageComponent {
    const res = new PageHeroSmallPageComponent(this.toJson());

    if (this.overline !== undefined) {
      res.overline = transformer.transform(this.overline);
    }
    res.heading = transformer.transform(this.heading);

    return res;
  }
}
