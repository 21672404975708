import {BaseWebsiteResource} from "./base-website-resource";
import {LicenseClass} from "./license-class";
import {ApogeeFile} from "./apogee-file";

export class Vehicle extends BaseWebsiteResource {
  public model: string;
  public brand: string;
  public image: ApogeeFile;
  public licenseClasses: LicenseClass[];


  constructor(vehicle: any = {}) {
    super(vehicle);
    this.model = vehicle.model ?? "";
    this.brand = vehicle.brand ?? "";
    this.image = new ApogeeFile(vehicle.image);
    this.licenseClasses = (vehicle.licenseClasses || []).map((c: any) => new LicenseClass(c));
  }


  public override toJson(): any {
    return {
      ...super.toJson(),
      model: this.model,
      brand: this.brand,
      image: this.image,
      licenseClasses: this.licenseClasses.map(({id}) => ({id})),
    };
  }

  public override getLabel(): string {
    return `${this.brand} ${this.model}`
  }

  public override clone(): Vehicle {
    return new Vehicle({...this.toJson(), id: undefined});
  }
}
