import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class GuaranteesPageComponent extends SectionPageComponent<
  GuaranteesPageComponent,
  'guarantees'
> {
  public subTitle: string;
  constructor(el: any = {}) {
    super(
      el,
      'guarantees',
      {hasTitle: true, required: true},
      {hasText: false, required: false},
    );

    this.subTitle = el.subTitle || '';
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      subTitle: this.subTitle,
    };
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): GuaranteesPageComponent {
    const res = new GuaranteesPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.subTitle = transformer.transform(this.subTitle);

    return res;
  }

  getSearchKeys(): string[] {
    return [];
  }
}
