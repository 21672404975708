import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class SubpageOverviewPageComponent extends SectionPageComponent<
  SubpageOverviewPageComponent,
  'subpageOverview'
> {
  constructor(el: any = {}) {
    super(
      el,
      'subpageOverview',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): SubpageOverviewPageComponent {
    const res = new SubpageOverviewPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
