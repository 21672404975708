const localizedDayNames = [
  'Montag',
  'Dienstag',
  'Mittwoch',
  'Donnerstag',
  'Freitag',
  'Samstag',
  'Sonntag',
] as const;
const dayNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export class OpeningTime {
  public readonly id: string | undefined;
  public dayOfWeek: number;
  public openingHour: number;
  public openingMinute: number;
  public closingHour: number;
  public closingMinute: number;

  constructor(el: any = {}) {
    this.id = el.id;
    this.dayOfWeek = el.dayOfWeek || 0;
    this.openingHour = el.openingHour || 0;
    this.openingMinute = el.openingMinute || 0;
    this.closingHour = el.closingHour || 0;
    this.closingMinute = el.closingMinute || 0;
  }

  public toJson(): any {
    return {
      id: this.id,
      dayOfWeek: this.dayOfWeek,
      openingHour: this.openingHour,
      openingMinute: this.openingMinute,
      closingHour: this.closingHour,
      closingMinute: this.closingMinute,
    };
  }

  public getDayOfWeekName(): (typeof dayNames)[number] {
    return dayNames[this.dayOfWeek];
  }

  public getLocalizedDayOfWeekName(): (typeof localizedDayNames)[number] {
    return localizedDayNames[this.dayOfWeek];
  }

  public getOpeningTime(): string {
    return `${('00' + (this.openingHour ?? 0)).slice(-2)}:${('00' + (this.openingMinute ?? 0)).slice(-2)}`;
  }

  public getClosingTime(): string {
    return `${('00' + (this.closingHour ?? 0)).slice(-2)}:${('00' + (this.closingMinute ?? 0)).slice(-2)}`;
  }

  get label(): string {
    return `${this.getLocalizedDayOfWeekName()} ${this.getOpeningTime()} - ${this.getClosingTime()}`;
  }
}
