import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class InfomaterialRequestPageComponent extends SectionPageComponent<
  InfomaterialRequestPageComponent,
  'infomaterial'
> {
  constructor(el: any = {}) {
    super(el, 'infomaterial');
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): InfomaterialRequestPageComponent {
    const res = new InfomaterialRequestPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);

    return res;
  }
}
