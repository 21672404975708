import {
  SectionWithOptionalCtaPageComponent
} from "./section-with-optional-cta.page-component";import {EMPTY_RICH_TEXT, RichText} from '../rich-text';
import {StringTransformer} from './string-transformer';
import {ApogeeFile} from "../apogee-file";

export class IllustrationTilesTile {
  public text: RichText;
  public title: string;
  public illustration: ApogeeFile

  constructor(el: any = {}) {
    this.text = el.text ?? EMPTY_RICH_TEXT;
    this.title = el.title;
    this.illustration = new ApogeeFile(el.illustration);
  }

  public applyStringTransformer(transformer: StringTransformer) {
    const res = new IllustrationTilesTile(this);

    res.title = transformer.transform(this.title);
    res.text.stringTransformer = transformer;

    return res;
  }
}

export class IllustrationTilesPageComponent extends SectionWithOptionalCtaPageComponent<IllustrationTilesPageComponent, 'illustrationTiles'> {
  public tiles: IllustrationTilesTile[];


  public override getSearchKeys(): string[] {
    return ["tiles.text.plainText"];
  }


  constructor(el: any = {}) {
    super(el, 'illustrationTiles');

    this.tiles = (el.tiles || []).map((tile: any) => new IllustrationTilesTile(tile));
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      tiles: this.tiles,
    };
  }
  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new IllustrationTilesPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.tiles = this.tiles.map(tile => tile.applyStringTransformer(transformer));

    return res;
  }
}
