import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';
import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';

export class TextWithImagePageComponent extends SectionWithOptionalCtaPageComponent<
  TextWithImagePageComponent,
  'textWithImage'
> {
  public image: ApogeeFile;
  public imageSide: 'left' | 'right';
  public containImage: boolean;

  constructor(el: any = {}) {
    super(
      el,
      'textWithImage',
      {hasTitle: true, required: true},
      {hasText: true, required: true},
    );

    this.image = new ApogeeFile(el.image);
    this.imageSide = el.imageSide ?? 'right';
    this.containImage = el.containImage ?? false
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      image: this.image,
      imageSide: this.imageSide,
      containImage: this.containImage,
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle'];
  }
  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new TextWithImagePageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }
}
