export abstract class BaseObject {
  public id: string | undefined;

  protected constructor(el: any = {}) {
    if (!el) {
      el = {};
    }
    this.id = el.id;
  }

  public toJson(): any {
    return {
      id: this.id,
    }
  }

  public toRef(): any {
    return {
      id: this.id,
    }
  }
}
