import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User, UserPrivilege, UserRole} from '@shared/model/user';
import {isSortable} from '@shared/model';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private userSubject: ReplaySubject<User> = new ReplaySubject<User>();

  constructor(private httpClient: HttpClient) {}

  public loadUser(): void {
    this.httpClient
      .get(`${environment.apiUrl}/api/user`)
      .pipe(map((user: any) => new User(user)))
      .subscribe({
        next: (user) => {
          this.userSubject.next(user);
        },
        error: (error) => {
          if (error.status === 401) {
            this.userSubject.next(new User({}));
          } else {
            console.error('Error loading user', error);
          }
        },
      });
  }

  public getUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public getPrivileges(): Observable<UserPrivilege[]> {
    return this.getUser().pipe(map(user => user.privileges));
  }

  public getWebsiteId(): Observable<string | null> {
    return this.getUser().pipe(map(user => user.websiteId));
  }

  public requestPermissions(websiteId: string): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}/api/user/access-request/${websiteId}`, {});
  }

  public setUserWebsite(websiteId: string | null): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}/api/user/website`, {
      websiteId,
    });
  }

  listAll(): Observable<User[]> {
    return this.httpClient.get(`${environment.apiUrl}/api/user/users`).pipe(
      map((websites: any) => {
        const res = (websites || []).map((user: any) => new User(user));
        if (isSortable(res[0])) {
          res.sort(res[0].getComparator());
        }
        return res;
      }),
    );
  }

  delete(userId: string): Observable<void> {
    return this.httpClient
        .delete(`${environment.apiUrl}/api/user/users/${userId}`)
        .pipe(map((user: any) => {}));
  }

  get(id: any): Observable<User> {
    return this.httpClient
      .get(`${environment.apiUrl}/api/user/users/${id}`)
      .pipe(map((user: any) => new User(user)));
  }

  update(user: User): Observable<User> {
    return this.httpClient
      .put(`${environment.apiUrl}/api/user/users`, user.toJson())
      .pipe(map((user: any) => new User(user)));
  }

  create(user: User): Observable<User> {
    return this.httpClient
      .post(`${environment.apiUrl}/api/user/users`, user.toJson())
      .pipe(map((user: any) => new User(user)));
  }

  getRoles(): Observable<UserRole[]> {
    return this.httpClient
      .get(`${environment.apiUrl}/api/user/roles`)
      .pipe(map((userRole: any) => (userRole || []).map((r: any) => new UserRole(r))));
  }
}
