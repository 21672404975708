import {AbstractPageDefiningWebsiteResource} from "./abstract-page-defining-website-resource";

export class Course extends AbstractPageDefiningWebsiteResource {
  public title: string;
  public description: string;
  public registrationFlow: 'B2B' | 'B2C';
  public allowRegistrationWithoutDate: boolean;
  public classCategories: string[];

  constructor(course: any = {}) {
    super(course);
    this.title = course.title ?? '';
    this.description = course.description ?? '';
    this.registrationFlow = course.registrationFlow === 'B2B' ? 'B2B' : 'B2C';
    this.allowRegistrationWithoutDate =
      course.allowRegistrationWithoutDate ?? false;
    this.classCategories = course.classCategories ?? [];
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      description: this.description,
      registrationFlow: this.registrationFlow,
      allowRegistrationWithoutDate: this.allowRegistrationWithoutDate,
      classCategories: this.classCategories,
    };
  }

  public override getSearchIndex(): string[] {
    return [...super.getSearchIndex(), this.title];
  }

  override getLabel(): string {
    return this.title;
  }

  public override clone(): Course {
    return new Course({...this.toJson(), id: undefined});
  }
}
