import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './';

export class NarrowRichTextPageComponent extends SectionPageComponent<
  NarrowRichTextPageComponent,
  'narrowRichText'
> {
  constructor(el: any = {}) {
    super(
      el,
      'narrowRichText',
      {hasTitle: true, required: false},
      {hasText: true, required: true},
    );
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): NarrowRichTextPageComponent {
    const res = new NarrowRichTextPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
    };
  }
}
