import {PageComponent} from './page-component';
import {PageComponentType} from './page-component-type';

import {
  AccordionPageComponent,
  BlogPostPageComponent,
  BlogPostsPageComponent,
  ContactPageComponent,
  CTAPageComponent,
  DownloadsPageComponent,
  ImageGalleryPageComponent,
  InfomaterialRequestPageComponent,
  KPISPageComponent,
  LocationMapPageComponent,
  LocationsPageComponent,
  NarrowRichTextPageComponent,
  NavigationTilesPageComponent,
  PageHeroPageComponent,
  PageHeroSmallPageComponent,
  PartnersPageComponent,
  QuickFactsPageComponent,
  ReviewsMarqueePageComponent,
  RichTextPageComponent,
  RichTextTilesPageComponent,
  StepsPageComponent,
  TablePageComponent,
  TeamMembersPageComponent,
  TestimonialPageComponent,
  TextWithImagePageComponent,
  TimetablePageComponent,
  VehiclesPageComponent,
  IllustrationTilesPageComponent,
  SlotPageComponent,
  GuaranteesPageComponent,
  TodoListPageComponent,
  ImageTileGridPageComponent,
  CourseRegistrationPageComponent,
  IndividualDrivingLicensePosterSectionPageComponent,
  LicenseClassInfoPageComponent,
  SalestoolEntrypointPageComponent,
  NewsPageComponent,
  NewsItemContentPageComponent,
  CertificationsPageComponent,
  KeyBenefitsPageComponent,
  LocationsOverviewPageComponent,
  SubpageOverviewPageComponent,
  SalestoolIntensiveCourseOverviewPageComponent,
  CourseListPageComponent, ImprintPageComponent,
} from './components-index';

export class PageComponentFactory {
  public create(data: any): PageComponent<any, any> | undefined {
    const type: PageComponentType = data.type;

    const constructors: {
      [key in PageComponentType]: (data: any) => PageComponent<any, any>;
    } = {
      pageHero: (data: any) => new PageHeroPageComponent(data),
      accordion: (data: any) => new AccordionPageComponent(data),
      reviewsMarquee: (data: any) => new ReviewsMarqueePageComponent(data),
      steps: (data: any) => new StepsPageComponent(data),
      table: (data: any) => new TablePageComponent(data),
      blogPosts: (data: any) => new BlogPostsPageComponent(data),
      subpageOverview: (data: any) => new SubpageOverviewPageComponent(data),
      blogPost: (data: any) => new BlogPostPageComponent(data),
      kpis: (data: any) => new KPISPageComponent(data),
      quickFacts: (data: any) => new QuickFactsPageComponent(data),
      teamMembers: (data: any) => new TeamMembersPageComponent(data),
      testimonial: (data: any) => new TestimonialPageComponent(data),
      courseRegistration: (data: any) =>
        new CourseRegistrationPageComponent(data),
      partners: (data: any) => new PartnersPageComponent(data),
      vehicles: (data: any) => new VehiclesPageComponent(data),
      narrowRichText: (data: any) => new NarrowRichTextPageComponent(data),
      locationMap: (data: any) => new LocationMapPageComponent(data),
      locations: (data: any) => new LocationsPageComponent(data),
      locationsOverview: (data: any) =>
        new LocationsOverviewPageComponent(data),
      imageGallery: (data: any) => new ImageGalleryPageComponent(data),
      illustrationTiles: (data: any) =>
        new IllustrationTilesPageComponent(data),
      contact: (data: any) => new ContactPageComponent(data),
      infomaterial: (data: any) => new InfomaterialRequestPageComponent(data),
      downloads: (data: any) => new DownloadsPageComponent(data),
      cta: (data: any) => new CTAPageComponent(data),
      navigationTiles: (data: any) => new NavigationTilesPageComponent(data),
      smallPageHero: (data: any) => new PageHeroSmallPageComponent(data),
      richText: (data: any) => new RichTextPageComponent(data),
      richTextTiles: (data: any) => new RichTextTilesPageComponent(data),
      textWithImage: (data: any) => new TextWithImagePageComponent(data),
      timetable: (data: any) => new TimetablePageComponent(data),
      slot: (data: any) => new SlotPageComponent(data),
      guarantees: (data: any) => new GuaranteesPageComponent(data),
      todoList: (data: any) => new TodoListPageComponent(data),
      imageTileGrid: (data: any) => new ImageTileGridPageComponent(data),
      individualDrivingLicensePosterSection: (data: any) =>
        new IndividualDrivingLicensePosterSectionPageComponent(data),
      licenseClassInfo: (data: any) => new LicenseClassInfoPageComponent(data),
      courseList: (data: any) => new CourseListPageComponent(data),
      salestoolEntrypoint: (data: any) =>
        new SalestoolEntrypointPageComponent(data),
      news: (data: any) => new NewsPageComponent(data),
      newsItemContent: (data: any) => new NewsItemContentPageComponent(data),
      certifications: (data: any) => new CertificationsPageComponent(data),
      keyBenefits: (data: any) => new KeyBenefitsPageComponent(data),
      salestoolIntensiveCourseOverview: (data: any) => new SalestoolIntensiveCourseOverviewPageComponent(data),
      imprint: (data: any) => new ImprintPageComponent(data),
    };

    return constructors[type] ? constructors[type](data) : undefined;
  }
}
