import {BaseWebsiteResource} from "./base-website-resource";
import {ApogeeFile} from "./apogee-file";

export class Partner extends BaseWebsiteResource {
  public name: string;
  public link?: string;
  public logo: ApogeeFile;


  constructor(partner: any = {}) {
    super(partner);
    this.name = partner.name ?? "";
    this.link = partner.link;
    this.logo = new ApogeeFile(partner.logo);
  }


  public override toJson(): any {
    return {
      ...super.toJson(),
      name: this.name,
      link: this.link,
      logo: this.logo,
    };
  }

  public override getSearchIndex(): string[] {
    return [
      ...super.getSearchIndex(),
      this.name,
      this.link || "",
    ];
  }

  public override clone(): Partner {
    return new Partner({...this.toJson(), id: undefined});
  }
}
