import {ApogeeFile} from "./apogee-file";
import {BaseObject} from "./base-object";

export class WebsiteSettings extends BaseObject {

  public logo: ApogeeFile;

  constructor(el: any = {}) {
    super(el);

    if (!el) {
      el = {};
    }

    this.logo = new ApogeeFile(el.logo);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      logo: this.logo,
    };
  }
}
