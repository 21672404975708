import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';
import {ApogeeFile} from '../apogee-file';
import {Link} from '../link';
import {BaseObject} from '../base-object';

export class NavigationTilesPageComponentLink extends BaseObject {
  public title: string;
  public description: string;
  public link: Link;
  public icon: ApogeeFile;

  constructor(el: any = {}) {
    super(el);
    this.title = el.title;
    this.link = Link.create(el.link);
    this.description = el.description;
    this.icon = new ApogeeFile(el.icon);
  }

  public applyStringTransformer(
    transformer: StringTransformer,
  ): NavigationTilesPageComponentLink {
    const res = new NavigationTilesPageComponentLink(this.toJson());

    res.title = transformer.transform(this.title);
    res.description = transformer.transform(this.description);

    return res;
  }

  public override toJson(): any {
    return {
      title: this.title,
      description: this.description,
      link: this.link.toJson(),
      icon: this.icon,
    };
  }
}

export class NavigationTilesPageComponent extends SectionPageComponent<
  NavigationTilesPageComponent,
  'navigationTiles'
> {
  constructor(el: any = {}) {
    super(
      el,
      'navigationTiles',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );
    this.image = new ApogeeFile(el.image);
    this.cta = Link.create(el.cta);
    this.links = (el.links || []).map(
      (link: any) => new NavigationTilesPageComponentLink(link),
    );
  }

  public image: ApogeeFile;
  public cta: Link;
  public links: NavigationTilesPageComponentLink[];

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      image: this.image,
      cta: this.cta.toJson(),
      links: this.links.map(link => link.toJson()),
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): NavigationTilesPageComponent {
    const res = new NavigationTilesPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.links = this.links.map(link => link.applyStringTransformer(transformer));

    return res;
  }
}
