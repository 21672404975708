import {EMPTY_RICH_TEXT, RichText} from '../rich-text';
import {StringTransformer} from './string-transformer';
import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';

export class StepsPageComponentStep {
  public title: string;
  public text: RichText;

  constructor(el: any = {}) {
    this.title = el.title;
    this.text = el.text ?? EMPTY_RICH_TEXT;
  }

  public applyStringTransformer(transformer: StringTransformer) {
    const res = new StepsPageComponentStep(this);

    res.title = transformer.transform(this.title);
    res.text.stringTransformer = transformer;

    return res;
  }
}

export class StepsPageComponent extends SectionWithOptionalCtaPageComponent<
  StepsPageComponent,
  'steps'
> {
  public steps: StepsPageComponentStep[] = [];

  constructor(el: any = {}) {
    super(
      el,
      'steps',
      {hasTitle: true, required: false},
      {hasText: true, required: false},
    );

    this.steps = (el.steps || []).map(
      (r: any) => new StepsPageComponentStep(r),
    );
  }

  public override getSearchKeys(): string[] {
    return [
      'sectionTitle',
      'sectionText.plainText',
      'steps.title',
      'steps.text.plainText',
      'link.title',
    ];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new StepsPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};
    res.steps = this.steps.map(step =>
      step.applyStringTransformer(transformer),
    );

    return res;
  }
  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      steps: this.steps,
    };
  }
}
