import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  brynIconCloudUpload,
  brynIconCritical,
  brynIconExit,
  brynIconGrid, brynIconInfo,
  BrynIconRegistry,
  brynIconSearchOff,
  brynIconVisibility,
  brynIconVisibilityOff,
  BrynUIModule,
} from 'brynui';
import {HttpClientModule} from '@angular/common/http';
import {OAuthModule} from 'angular-oauth2-oidc';
import {UserApiService} from './shared/services/user-api.service';
import {WebsiteService} from './shared/services/website.service';
import {environment} from '../environments/environment';
import * as Sentry from "@sentry/angular";
import {DeleteConfirmationModalComponent} from './delete-confirmation-modal/delete-confirmation-modal.component';
import {NoPermissionsOverlayComponent} from './no-permissions-overlay/no-permissions-overlay.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  DiscardChangesConfirmationModalComponent
} from "./discard-changes-confirmation-modal/discard-changes-confirmation-modal.component";
import {Router} from "@angular/router";

@NgModule({
  declarations: [
    AppComponent,
    DeleteConfirmationModalComponent,
    NoPermissionsOverlayComponent,
    DiscardChangesConfirmationModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrynUIModule.forRoot(),
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [`${environment.apiUrl}/api`],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [UserApiService, WebsiteService,    {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private iconsRegistry: BrynIconRegistry) {
    iconsRegistry.registerIcons([
      brynIconInfo,
      brynIconExit,
      brynIconCloudUpload,
      brynIconGrid,
      brynIconCritical,
      brynIconVisibility,
      brynIconSearchOff,
      brynIconVisibilityOff,
    ]);
  }
}
