import {PageComponent} from "./page-component";
import {StringTransformer} from "./string-transformer";
import {PageComponentFactory} from "./page-component-factory";
import {PageComponentType} from "./page-component-type";

export class SlotPageComponent extends PageComponent<SlotPageComponent, 'slot'> {

  public websiteSlotComponentsId: string | undefined;

  public components: PageComponent<any, any>[];
  public childrenRemovable: boolean;
  public allowedTypes: PageComponentType[];
  public maxChildren: number | null;
  public minChildren: number | null;

  constructor(el: any) {
    super(el, 'slot');

    this.childrenRemovable = el.childrenRemovable ?? false;
    this.allowedTypes = el.allowedTypes || [];
    this.maxChildren = el.maxChildren;
    this.minChildren = el.minChildren;

    const isPageComponent = (component: PageComponent<any, any> | undefined): component is PageComponent<any, any> => !!component;
    this.components = (el.components || [])
      .map((c: any) => new PageComponentFactory().create({...c, global: this.global}))
      .filter(isPageComponent);
  }

  applyStringTransformer(transformer: StringTransformer): SlotPageComponent {
    // no need to implement this because the slot component is not used on the website
    return this;
  }

  getSearchKeys(): string[] {
    // no need to implement this because the slot component is not used on the website
    return [];
  }

  override toJson(): any {
    return {
      ...super.toJson(),
      components: (this.components || []).map(component => component.toJson()),
      childrenRemovable: this.childrenRemovable,
      allowedTypes: this.allowedTypes,
      maxChildren: this.maxChildren,
      minChildren: this.minChildren
    };
  }

}
