import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';
import {StringTransformer} from './string-transformer';

export class TeamMembersPageComponent extends SectionWithOptionalCtaPageComponent<
  TeamMembersPageComponent,
  'teamMembers'
> {
  public grid: boolean;
  public filterByLocationContext: boolean = true;
  public filterByLicenseClassContext: boolean = true;

  constructor(el: any = {}) {
    super(
      el,
      'teamMembers',
      {hasTitle: true, required: true},
      {hasText: true, required: false},
    );

    this.grid = el.grid || false;
    if (el.filterByLocationContext !== null) {
      this.filterByLocationContext = el.filterByLocationContext;
    }
    if (el.filterByLicenseClassContext !== null) {
      this.filterByLicenseClassContext = el.filterByLicenseClassContext;
    }
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle'];
  }

  public override applyStringTransformer(transformer: StringTransformer) {
    const res = new TeamMembersPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      grid: this.grid,
      filterByLocationContext: this.filterByLocationContext,
      filterByLicenseClassContext: this.filterByLicenseClassContext,
    };
  }
}
