import {WebsiteSettings} from './website-settings';

export class Website {
  public id: string | undefined;
  public domain: string;
  public logoSlug: string;
  public name: string;
  public legalName: string;
  public areaProtectionZip: string[];
  public maxiUrl: string;
  public fahrschulmanagerId: string;

  public settings: WebsiteSettings;

  constructor(el: any = {}) {
    this.id = el.id;
    this.domain = el.domain ?? '';
    this.logoSlug = el.logoSlug || '';
    this.name = el.name || '';
    this.legalName = el.legalName || '';
    this.areaProtectionZip = el.areaProtectionZip || [];
    this.maxiUrl = el.maxiUrl || '';
    this.fahrschulmanagerId = el.fahrschulmanagerId || '';

    this.settings = new WebsiteSettings(el.settings);
  }

  public toJson(): any {
    return {
      id: this.id,
      domain: this.domain,
      logoSlug: this.logoSlug,
      name: this.name,
      legalName: this.legalName,
      areaProtectionZip: this.areaProtectionZip,
      maxiUrl: this.maxiUrl,
      fahrschulmanagerId: this.fahrschulmanagerId,

      settings: this.settings.toJson(),
    };
  }
}

export const globalWebsite = new Website({
  id: null,
  domain: 'GLOBAL',
  name: 'Academy Franchise',
});
