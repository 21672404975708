import {SectionPageComponent} from './section-page-component';
import {StringTransformer} from './string-transformer';

export class KPISPageComponentKPI {
  public value: string;
  public label: string;

  constructor(el: any = {}) {
    this.value = el.value;
    this.label = el.label;
  }

  public applyStringTransformer(
    transformer: StringTransformer,
  ): KPISPageComponentKPI {
    const res = new KPISPageComponentKPI(this);

    res.value = transformer.transform(this.value);
    res.label = transformer.transform(this.label);

    return res;
  }
}

export class KPISPageComponent extends SectionPageComponent<
  KPISPageComponent,
  'kpis'
> {
  public kpis: KPISPageComponentKPI[] = [];

  constructor(el: any = {}) {
    super(el, 'kpis', {hasTitle: true, required: false});

    this.kpis = (el.kpis || []).map((r: KPISPageComponentKPI) => {
      return new KPISPageComponentKPI(r);
    });
  }

  public override getSearchKeys(): string[] {
    return ['sectionTitle', 'kpis.value', 'kpis.label'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): KPISPageComponent {
    const res = new KPISPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.kpis = this.kpis.map(kpi => kpi.applyStringTransformer(transformer));

    return res;
  }

  public override toJson(): any {
    const parent: any = super.toJson();
    return {
      ...parent,
      kpis: this.kpis,
    };
  }
}
