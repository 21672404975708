import {StringTransformer} from './string-transformer';
import {SectionWithOptionalCtaPageComponent} from './section-with-optional-cta.page-component';

export class LocationsOverviewPageComponent extends SectionWithOptionalCtaPageComponent<
  LocationsOverviewPageComponent,
  'locationsOverview'
> {
  constructor(el: any = {}) {
    super(
      el,
      'locationsOverview',
      {hasTitle: true, required: false},
      {hasText: false, required: false},
    );
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
    };
  }

  public override getSearchKeys(): string[] {
    return ['sectionText.plainText', 'sectionTitle'];
  }

  public override applyStringTransformer(
    transformer: StringTransformer,
  ): LocationsOverviewPageComponent {
    const res = new LocationsOverviewPageComponent(this.toJson());

    res.sectionTitle = transformer.transform(this.sectionTitle);
    res.sectionText = {...res.sectionText, stringTransformer: transformer};

    return res;
  }
}
