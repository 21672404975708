import {EMPTY_RICH_TEXT, RichText} from './rich-text';
import {ApogeeFile} from './apogee-file';
import {AbstractPageDefiningWebsiteResource} from "./abstract-page-defining-website-resource";

const types = [
  'CAR',
  'MOTORCYCLE',
  'TRUCK',
  'BUS',
  'TRACTOR',
  'OTHER',
] as const;

export const blogPostCategories = [...types];

export const blogPostCategoryLabels: {[key in BlogPostCategory]: string} = {
  OTHER: 'Sonstiges',
  TRACTOR: 'Traktor',
  CAR: 'Auto',
  MOTORCYCLE: 'Motorrad',
  BUS: 'Bus',
  TRUCK: 'LKW',
};

export type BlogPostCategory = (typeof types)[number];

export class BlogPost extends AbstractPageDefiningWebsiteResource {
  public text: RichText;
  public title: string;
  public datePosted: Date;
  public dateUpdated: Date;
  public category: BlogPostCategory;
  public image: ApogeeFile;

  constructor(post: any = {}) {
    super(post);
    this.title = post.title;
    this.text = post.text ?? EMPTY_RICH_TEXT;
    this.datePosted = post.datePosted ? new Date(post.datePosted): new Date();
    this.dateUpdated = post.dateUpdated ? new Date(post.dateUpdated): new Date();
    this.category = post.category || 'OTHER';
    this.image = new ApogeeFile(post.image);
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      title: this.title,
      text: this.text,
      datePosted: this.datePosted.getTime(),
      dateUpdated: this.dateUpdated.getTime(),
      category: this.category,
      image: this.image,
    };
  }

  public override getSearchIndex(): string[] {
    return [...super.getSearchIndex(), this.title, this.text.plainText];
  }

  override getLabel(): string {
    return this.title;
  }

  public override clone(): BlogPost {
    return new BlogPost({...this.toJson(), id: undefined});
  }
}
