import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {permissionGuard} from '../guards/permission-guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'website',
    loadChildren: () =>
      import('./website/website.module').then(m => m.WebsiteModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'team-member',
    loadChildren: () =>
      import('./team-member/team-member.module').then(m => m.TeamMemberModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'page',
    loadChildren: () => import('./page/page.module').then(m => m.PageModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'location',
    loadChildren: () =>
      import('./location/location.module').then(m => m.LocationModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'course',
    loadChildren: () =>
      import('./course/course.module').then(m => m.CourseModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'license-class',
    loadChildren: () =>
      import('./license-class/license-class.module').then(
        m => m.LicenseClassModule,
      ),
    canActivate: [permissionGuard],
  },
  {
    path: 'license-class-category',
    loadChildren: () =>
      import('./license-class-category/license-class-category.module').then(
        m => m.LicenseClassCategoryModule,
      ),
    canActivate: [permissionGuard],
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./partner/partner.module').then(m => m.PartnerModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'vehicle',
    loadChildren: () =>
      import('./vehicle/vehicle.module').then(m => m.VehicleModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'blog-post',
    loadChildren: () =>
      import('./blog-post/blog-post.module').then(m => m.BlogPostModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'website-master-data',
    loadChildren: () =>
      import('./website-master-data/website-master-data.module').then(
        m => m.WebsiteMasterDataModule,
      ),
    canActivate: [permissionGuard],
  },
  {
    path: 'downloads',
    loadChildren: () =>
      import('./downloads/downloads.module').then(m => m.DownloadsModule),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./support/support.module').then(m => m.SupportModule),
  },
  {
    path: 'usps',
    loadChildren: () => import('./usps/usps.module').then(m => m.UspsModule),
    canActivate: [permissionGuard],
  },
  {
    path: 'registration-config',
    loadChildren: () =>
      import('./registration-config/registration-config.module').then(
        m => m.RegistrationConfigModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
