import {AbstractPageType} from "./abstract-page";

export class PageContext {
  public abstractPageType: AbstractPageType;
  public abstractPageEntityId: string;

  constructor(el: any = {}) {
    this.abstractPageType = el.abstractPageType;
    this.abstractPageEntityId = el.abstractPageEntityId;
  }
}
