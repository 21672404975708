import {AbstractPageDefiningWebsiteResource} from "./abstract-page-defining-website-resource";
import {PlaceAutocompleteResponse} from './google-places';
import {OpeningTime} from './opening-time';
import {ApogeeFile} from './apogee-file';
import {EMPTY_RICH_TEXT, RichText} from "./rich-text";

export class Location extends AbstractPageDefiningWebsiteResource {
  public name: string;
  public description: RichText;
  public images: ApogeeFile[];
  public licenseClasses: string[];
  public openingTimes: OpeningTime[];
  public lessons: OpeningTime[];
  public autocompleteResponse: PlaceAutocompleteResponse | undefined;

  public readonly coords: {
    lat: number;
    lng: number;
  };
  public readonly formattedAddress: string;

  public phone?: string;
  public email?: string;
  public whatsapp?: string;

  constructor(el: any = {}) {
    super(el);
    this.name = el.name ?? '';
    this.description = el.description ?? EMPTY_RICH_TEXT;
    this.images = (el.images || []).map((i: any) => new ApogeeFile(i));
    this.licenseClasses = el.licenseClasses || [];
    this.openingTimes = (el.openingTimes || []).map(
      (l: any) => new OpeningTime(l),
    );
    this.lessons = (el.lessons || []).map((l: any) => new OpeningTime(l));
    this.autocompleteResponse = el.autocompleteResponse;
    this.phone = el.phone;
    this.email = el.email;
    this.whatsapp = el.whatsapp;
    this.coords = el.coords || {
      lat: 0,
      lng: 0,
    };
    this.formattedAddress = el.formattedAddress || '';
  }

  public override toJson(): any {
    return {
      ...super.toJson(),
      name: this.name,
      description: this.description,
      images: this.images,
      licenseClasses: this.licenseClasses,
      openingTimes: this.openingTimes.map(el => el.toJson()),
      lessons: this.lessons.map(el => el.toJson()),
      autocompleteResponse: this.autocompleteResponse
        ? {
            place_id: this.autocompleteResponse.place_id,
            description: this.autocompleteResponse.description,
          }
        : null,
      phone: this.phone,
      email: this.email,
      whatsapp: this.whatsapp,
    };
  }

  override getLabel(): string {
    return this.name;
  }

  public override getSearchIndex(): string[] {
    return [
      ...super.getSearchIndex(),
      this.name,
      this.phone ?? '',
      this.email ?? '',
      this.whatsapp ?? '',
    ];
  }

  public override clone(): Location {
    return new Location({...this.toJson(), id: undefined});
  }
}
